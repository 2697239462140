import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {CommonModule} from "@angular/common";
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import {CurrencyPipe} from "@angular/common"
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule }    from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { IonicStorageModule } from '@ionic/storage-angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CalendarModule } from 'ion2-calendar';

import {NgxPaginationModule} from 'ngx-pagination'
import { FilterPipe } from '@lib/pipes/filter.pipe'; // -> imported filter pipe

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
			BrowserModule,
      CommonModule,
      BrowserAnimationsModule,
      IonicStorageModule.forRoot(),
      IonicModule.forRoot({
          rippleEffect: false,
           mode: 'md'
      }),
      AppRoutingModule,
      HttpClientModule,
      CalendarModule,
      NgxPaginationModule,
      ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
      /*ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),*/


  ],
  providers: [
      { provide: Window, useValue: window },
      //StatusBar,
      //SplashScreen,
      NativeStorage,
      //LocalNotifications,
      //AndroidPermissions,
      InAppBrowser,
      CurrencyPipe,
      FilterPipe,
      //CameraPreview,
      //Camera,
      //C PopupService,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [CommonModule],
})
export class AppModule {}
